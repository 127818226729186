export enum PaymentMethodsEnum {
  card = 'card',
  cash = 'cash',
  manager = 'manager',
}

export enum CardPaymentMethodsEnum {
  card = 'card',
  applePay = 'applePay',
  googlePay = 'googlePay',
}

export enum PaymentRespStatusesEnum {
  success = 'Success',
  need3ds = 'Need3Ds',
  rejected = 'Rejected',
}

export enum CardTypesEnum {
  mastercard = 'MasterCard',
  visa = 'Visa',
  mir = 'Mir',
}
