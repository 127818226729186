import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '@shared/models';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardComponent {
  @Input() card: Card;
  @Input() activeCardId: string;

  @Output() selectCard = new EventEmitter<string>();

  public select(cardId: string): void {
    this.selectCard.emit(cardId);
  }
}
