import { ProgramModesEnum } from '@shared/enums';
import { ExtOptions } from '@shared/models';

export function getPackageViewId(id: string): string {
  return `package-${id}`;
}

export function trackByFn(index, item): string {
  return item?.id || `${index}`;
}
