import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, Self } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable, filter, map, takeUntil, tap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';

import { AuthSelectors, SendInputPhone, VerifyCode } from '@store/auth';
import { TimerService } from '@modules/login/services/timer.service';
import { NgOnDestroyService } from '@shared/services';
import { convertInputPhone, getFormattedPhone } from '@shared/utils';

declare let SMSRetriever: any;

export interface CodeForm {
  n1: FormControl<string>;
  n2: FormControl<string>;
  n3: FormControl<string>;
  n4: FormControl<string>;
}

@Component({
  templateUrl: './login-code-input.component.html',
  styleUrls: ['./login-code-input.component.scss'],
  providers: [NgOnDestroyService, TimerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCodeInputComponent implements OnInit {
  public isSendingCode$: Observable<boolean> = inject(Store).select(AuthSelectors.isSendingCode);
  public authErrorMessage$: Observable<string> = inject(Store).select(AuthSelectors.authErrorMessage);
  public inputPhone$: Observable<string> = inject(Store).select(AuthSelectors.inputPhone).pipe(map(convertInputPhone));

  public form: FormGroup<CodeForm>;

  public isTimeout$ = this.timerService?.timeLeft$.pipe(map(timeLeft => timeLeft > 0));
  public secondsLeft$ = this.timerService?.timeLeftForView$;

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private router: Router,
    private store: Store,
    private timerService: TimerService,
    private platform: Platform,
    private fb: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.listenForm();
    this.focusInput('n1');
    this.listenAuthErrorMessage();
    await this.timerService.init();

    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.startSmsRetriever();
      }
    });
  }

  private initForm(): void {
    const validators = [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern('^[0-9]*$')];

    this.form = this.fb.group({
      n1: ['', validators],
      n2: ['', validators],
      n3: ['', validators],
      n4: ['', validators],
    });
  }

  private startSmsRetriever(): void {
    if (!(window as any)?.SMSRetriever) {
      return;
    }

    SMSRetriever.startWatching(
      (msg: string) => {
        if (msg.includes('justfood')) {
          const code = this.extractVerificationCode(msg);
          if (code?.length >= 4) {
            this.form.patchValue({
              n1: code[0],
              n2: code[1],
              n3: code[2],
              n4: code[3],
            });
          }
        }
      },
      (error: any) => {
        console.error('Error starting SMS Retriever:', error);
      },
    );
  }

  /**
   * Возвращает код из СМС в формате "1234"
   */
  private extractVerificationCode(message: string): string | null {
    const codeRegex = /\b(\d{4})\b/;
    const matches = message.match(codeRegex);
    return matches ? matches[0] : null;
  }

  public goToInputPhone(): void {
    this.router.navigate(['login', 'phone']);
  }

  public checkVerificationCode(): void {
    const { n1, n2, n3, n4 } = this.form.getRawValue();
    const code = [n1, n2, n3, n4].join('');

    this.store.dispatch(new VerifyCode(code));
  }

  public resendVerificationCode(phone: string): void {
    this.store.dispatch(new SendInputPhone(getFormattedPhone(phone)));
    this.timerService.start(phone);
  }

  private listenForm(): void {
    this.form.valueChanges
      .pipe(
        tap(data => {
          // Переключаем фокус на первый пустой инпут
          const nextInput = Object.entries(data).find(n => !n[1].length)?.[0];
          this.focusInput(nextInput ? nextInput : 'n1');
        }),
        filter(() => this.form.valid),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(() => {
        this.checkVerificationCode();
      });
  }

  private listenAuthErrorMessage(): void {
    this.authErrorMessage$
      .pipe(
        filter<string>(Boolean),
        tap(() => {
          this.form.patchValue({ n1: '', n2: '', n3: '', n4: '' });
          this.focusInput('n1');
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  public focusInput(className: string): void {
    setTimeout(() => {
      const input: HTMLInputElement = this.dom.querySelector(`.${className}`);

      if (input) {
        input.focus();
      }
    }, 30);
  }

  public onKeyDown(e: KeyboardEvent, className: string): void {
    if (e.key !== 'Backspace') {
      return;
    }

    this.focusInput(className);
    this.form.patchValue({ [className]: '' });
  }
}
