import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { Observable, first, map, tap } from 'rxjs';

import { TimerService } from '@modules/login/services/timer.service';
import { Store } from '@ngxs/store';
import {
  AuthSelectors,
  InitAgreements,
  SendInputPhone,
  SetNotificationsAgreement,
  SetPrivacyAgreement,
  SetUrlAfterAuth,
} from '@store/auth';

@Component({
  templateUrl: './login-phone-input.component.html',
  styleUrls: ['./login-phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPhoneInputComponent implements OnInit {
  public isGettingCode$: Observable<boolean> = inject(Store).select(AuthSelectors.isGettingCode);
  public codeError$: Observable<string> = inject(Store)
    .select(AuthSelectors.codeError)
    .pipe(tap(() => this.phoneNumberControl.setErrors({ serverError: true })));
  public notificationsAgreement$: Observable<boolean> = inject(Store).select(AuthSelectors.notificationsAgreement);
  public privacyAgreement$: Observable<boolean> = inject(Store).select(AuthSelectors.privacyAgreement);

  public phoneErrorMessage = 'Некорректный телефонный номер';

  public phoneNumberControl = new FormControl('', {
    validators: [Validators.required, Validators.minLength(10)],
  });

  public isTimeout$ = this.timerService?.timeLeft$.pipe(map(timeLeft => timeLeft > 0));

  public isShowAgreements$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) private dom: Document, private router: Router, private timerService: TimerService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new InitAgreements());
    this.focusInput('auth-phone-input');

    this.isShowAgreements$ = this.privacyAgreement$.pipe(first());
  }

  public sendPhone(): void {
    if (this.phoneNumberControl.invalid) {
      this.phoneNumberControl.markAsTouched();
      return;
    }

    const phone = this.phoneNumberControl.value;

    this.store.dispatch(new SendInputPhone(phone));
  }

  public privacyAgreementClick(e: Event): void {
    const isChecked = (e.currentTarget as any).checked;

    this.store.dispatch(new SetPrivacyAgreement(isChecked));
  }

  public notificationsAgreementClick(e: Event): void {
    const isChecked = (e.currentTarget as any).checked;

    this.store.dispatch(new SetNotificationsAgreement(isChecked));
  }

  public goToSubList(): void {
    this.store.dispatch(new SetUrlAfterAuth(null));
    this.router.navigate(['cabinet', 'create']);
  }

  private focusInput(className: string): void {
    setTimeout(() => {
      const input: HTMLInputElement = this.dom.querySelector(`.${className}`);

      if (input) {
        input.focus();
      }
    }, 1000);
  }

  public onInput(input: string): void {
    if (input.length === 2 && input[0] === '+' && input[1] !== '7') {
      this.phoneNumberControl.patchValue('+7' + input.slice(1));
    }
  }
}
