import { ChangeDetectionStrategy, Output, EventEmitter, Input, Component } from '@angular/core';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalLayoutComponent {
  @Input() title: string;
  @Input() alertText: string;
  @Input() alertClass: string = '';
  @Input() showBack = false;
  @Input() closeIcon = false;

  @Output() onClose = new EventEmitter<void>();
  @Output() onGoBack = new EventEmitter<void>();
}
