<div class="card" [class.active]="card.guid === activeCardId" (click)="select(card.guid)">
  <div class="card-icon-wrap">
    <div class="icon-card">
      <app-icon
        *ngIf="['mastercard', 'visa', 'mir'].includes(card.cardType.toLowerCase())"
        [class]="'icon ' + card.cardType.toLowerCase()"
        [icon]="card.cardType.toLowerCase()"
      ></app-icon>
    </div>

    <span> {{ card.cardType }} {{ card.cardMaskedNum.slice(-5) }}</span>
  </div>

  <app-radio-toggler class="save-card-toggle" [value]="card.guid === activeCardId"></app-radio-toggler>
</div>
