<ng-container
  *ngIf="{
  isGettingCode: isGettingCode$ | async,
  codeError: codeError$ | async,
  isTimeout: isTimeout$ | async
} as data"
>
  <form class="form form-phone" [ngClass]="{ 'locked': data.isGettingCode }" (submit)="sendPhone()">
    <app-text-input
      class="form-input mb-7"
      [formControl]="phoneNumberControl"
      customInputClass="auth-phone-input"
      inputType="tel"
      autocomplete="tel"
      inputId="phone"
      [errorMessages]="{
        'required': phoneErrorMessage,
        'serverError': data.codeError
      }"
      placeholder="Введите номер телефона"
      mask="+0 (000) 000-00-00"
      [showMaskTyped]="false"
      (inputValue)="onInput($event)"
    ></app-text-input>

    <button [disabled]="data.isGettingCode || data.isTimeout || phoneNumberControl.invalid" class="btn-base send-code-btn" type="submit">
      Далее
    </button>

    <div class="agreement text-14 text-center">
      Отправляя код, вы принимаете условия обработки персональных данных, соглашаетесь с
      <a class="agreement-link" href="https://justfood.pro/assets/documents/privacy_policy.pdf" target="_blank"
        >Политикой обработки персональных данных</a
      >, даёте
      <a class="agreement-link" href="https://justfood.pro/assets/documents/agreement_2.pdf" target="_blank"
        >согласие на получение электронных сообщений</a
      >
      и принимаете условия
      <a class="agreement-link" href="https://justfood.pro/assets/documents/oferta.pdf" target="_blank">публичной оферты</a>
    </div>
  </form>

  <p class="skip" (click)="goToSubList()">Пропустить</p>
</ng-container>
