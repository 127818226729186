export enum ProgramDescriptionEnum {
  Sport = 'Простые ингредиенты, овощи, птица',
  Fit = 'Сбалансированное меню с рыбой, супами и десертами',
  Life = 'Как в ресторане, только дома',
}

export enum ProgramDeliveryText {
  Sport = 'интервал доставки - 2 часа',
  Fit = 'интервал доставки - 1 час',
  Life = 'интервал доставки - 30 мин',
}
