import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { ClientMenuSelectors } from '@store/client-menu';
import { LoadCards, LoadPaymentDetails, PaymentSelectors } from '@store/payment';
import { NgOnDestroyService } from '@shared/services';
import { getDatesMap } from '@shared/utils';
import { PaymentDetails } from '@shared/models';
import { LocalStorageKeysEnum } from '@shared/enums';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() isImportant = false;

  public customizationPrice$: Observable<number> = inject(Store).select(ClientMenuSelectors.customizationPrice);
  public startedPaymentId$: Observable<string> = inject(Store).select(PaymentSelectors.startedPaymentId);
  public paymentDetails$: Observable<PaymentDetails> = inject(Store).select(PaymentSelectors.paymentDetails);
  public packagesDates$: Observable<string> = inject(Store)
    .select(ClientMenuSelectors.replacementDates)
    .pipe(map((data: string[]) => this.getReplacementDatesString(data)));

  public countdown: string | null = null;
  private intervalId: any;

  constructor(
    private store: Store,
    private modalCtrl: ModalController,
    private router: Router,
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenPaymentInfo();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private listenPaymentInfo(): void {
    this.startedPaymentId$
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        tap(paymentGuid => {
          this.store.dispatch([new LoadCards(paymentGuid), new LoadPaymentDetails(paymentGuid)]);
          this.listenPaymentDetails();
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  private listenPaymentDetails(): void {
    this.paymentDetails$
      .pipe(
        distinctUntilChanged(),
        tap(data => {
          this.getRepaymentStatus(data?.canBeRepaidAfterDate);
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  public canBeRepaidOrCanceled(canBeRepaidOrCanceledAfter: string): boolean {
    if (!canBeRepaidOrCanceledAfter) {
      return false;
    }

    const now = new Date();
    this.changeDetectorRef.markForCheck();
    return new Date(canBeRepaidOrCanceledAfter) > now;
  }

  public getRepaymentStatus(canBeRepaidOrCanceledAfter: string | null | undefined): void {
    const targetDate = new Date(canBeRepaidOrCanceledAfter);

    if (isNaN(targetDate.getTime()) || !canBeRepaidOrCanceledAfter) {
      this.countdown = null;
      return;
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      const now = new Date();
      const differenceInMs = targetDate.getTime() - now.getTime();

      if (differenceInMs <= 0) {
        clearInterval(this.intervalId);
        this.countdown = null;
        this.changeDetectorRef.markForCheck();
        return;
      }

      const seconds = Math.floor((differenceInMs / 1000) % 60);
      const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);
      const hours = Math.floor((differenceInMs / (1000 * 60 * 60)) % 24);
      const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
      const pad = (num: number) => num.toString().padStart(2, '0');

      let countdown = '';

      if (days > 0) {
        countdown += `${pad(days)}д `;
      }
      if (hours > 0 || days > 0) {
        countdown += `${pad(hours)}ч `;
      }
      countdown += `${pad(minutes)}:${pad(seconds)}`;
      this.countdown = countdown.trim();
      this.changeDetectorRef.markForCheck();
    }, 1000);
  }

  public cancel(): void {
    this.modalCtrl.dismiss(null, 'erase');
  }

  public async confirm(): Promise<void> {
    await Preferences.set({
      key: LocalStorageKeysEnum.shouldClosePaymentModal,
      value: JSON.stringify(true),
    });
    this.modalCtrl.dismiss(true, 'confirm');
  }

  public closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
    this.router.navigate(['/']);
  }

  private getReplacementDatesString(dates: string[]): string {
    const datesMap = getDatesMap(dates);

    return Object.values(datesMap)
      .map(item => {
        const days = item.days.sort((a, b) => a - b).join(', ');

        return `${days} ${item.month}`;
      })
      .join(', ');
  }
}
