import { ExtOptions } from '@shared/models';

export function getEatOptions(daysCount: number): Partial<ExtOptions> {
  const eatOnSaturday = daysCount % 6 === 0;
  const eatOnSaturdayAndSunday = daysCount % 7 === 0;

  return !daysCount
    ? {
        eatOnSaturday: false,
        eatOnSaturdayAndSunday: false,
      }
    : {
        eatOnSaturday: eatOnSaturday,
        eatOnSaturdayAndSunday: eatOnSaturdayAndSunday,
      };
}
