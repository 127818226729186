import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, from } from 'rxjs';

declare global {
  interface Window {
    cp: any;
    google: any;
    ApplePaySession: any;
  }
}

interface CustomHTMLScriptElement extends HTMLScriptElement {
  readyState?: any;
  onreadystatechange?: any;
}

interface ScriptObj {
  name: string;
  src: string;
}

interface ScriptsLoaderObj {
  [scriptName: string]: {
    loaded: boolean;
    src: string;
  };
}

const ScriptLibrary: ScriptObj[] = [
  {
    name: 'cloudpaymentsCheckout',
    src: 'https://widget.cloudpayments.ru/bundles/checkout',
  },
  {
    name: 'googlePay',
    src: 'https://pay.google.com/gp/p/js/pay.js',
  },
];

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private scriptsLoaderObj: ScriptsLoaderObj = {};

  constructor(@Inject(DOCUMENT) private dom: Document) {
    ScriptLibrary.forEach((scriptObj: ScriptObj) => {
      this.scriptsLoaderObj[scriptObj.name] = {
        loaded: false,
        src: scriptObj.src,
      };
    });
  }

  public loadScripts$(...scriptNames: string[]): Observable<any> {
    return from(this.loadScripts(...scriptNames));
  }

  public loadScripts(...scriptNames: string[]): Promise<any> {
    const promises: Promise<any>[] = [];

    scriptNames.forEach(scriptName => promises.push(this.loadScript(scriptName)));

    return Promise.all(promises);
  }

  private loadScript(scriptName: string): Promise<any> {
    return new Promise(resolve => {
      // resolve if already loaded
      if (this.scriptsLoaderObj[scriptName].loaded) {
        resolve({
          script: scriptName,
          loaded: true,
          status: 'Already Loaded',
        });
      } else {
        // load script
        const htmlScriptElement: CustomHTMLScriptElement = this.dom.createElement('script');

        htmlScriptElement.type = 'text/javascript';
        htmlScriptElement.src = this.scriptsLoaderObj[scriptName].src;

        if (htmlScriptElement.readyState) {
          // IE
          htmlScriptElement.onreadystatechange = () => {
            if (htmlScriptElement.readyState === 'loaded' || htmlScriptElement.readyState === 'complete') {
              htmlScriptElement.onreadystatechange = null;
              this.scriptsLoaderObj[scriptName].loaded = true;

              resolve({
                script: scriptName,
                loaded: true,
                status: 'Loaded',
              });
            }
          };
        } else {
          // Others
          htmlScriptElement.onload = () => {
            this.scriptsLoaderObj[scriptName].loaded = true;

            resolve({
              script: scriptName,
              loaded: true,
              status: 'Loaded',
            });
          };
        }

        htmlScriptElement.onerror = () =>
          resolve({
            script: scriptName,
            loaded: false,
            status: 'Loaded',
          });

        this.dom.getElementsByTagName('head')[0].appendChild(htmlScriptElement);
      }
    });
  }
}
