import { ProgramModesEnum } from '@shared/enums';
import { ExtOptions } from '@shared/models';
import { getEatOptions } from './get-eat-options.util';

export function getExtendedOptions(options: { daysCount: number; mode?: ProgramModesEnum; autoRenew: boolean }): ExtOptions {
  return {
    ...getEatOptions(options.daysCount),
    autoRenew: options.autoRenew,
    noBreakfastAndDinner: options.mode === ProgramModesEnum.NoBreakfast,
  } as ExtOptions;
}
