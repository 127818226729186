import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appValidateSrcset]',
})
export class ValidateSrcsetDirective implements OnInit {
  @Input() appValidateSrcset: string | undefined;
  @Input() fallbackSrc: string | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.appValidateSrcset) {
      const img = new Image();
      img.srcset = this.appValidateSrcset;

      img.onload = () => {
        this.renderer.setAttribute(this.el.nativeElement, 'srcset', this.appValidateSrcset as string);
      };

      img.onerror = () => {
        if (this.fallbackSrc) {
          this.renderer.setAttribute(this.el.nativeElement, 'srcset', this.fallbackSrc);
          this.renderer.addClass(this.el.nativeElement, 'error');
        } else {
          this.renderer.removeAttribute(this.el.nativeElement, 'srcset');
        }
      };
    }
  }
}
