export enum GendersEnum {
  male = 'Male',
  female = 'Female',
}

export enum TargetsEnum {
  loseWeight = 'loseWeight',
  keepShape = 'keepShape',
  gainWeight = 'gainWeight',
}

export enum ActivityLevelsEnum {
  low = 'low',
  middle = 'middle',
  high = 'high',
  extremelyHigh = 'extremelyHigh',
}
