<ion-content>
  <div class="wrapper">
    <div class="header">
      <app-icon class="header-icon" icon="logo-company"></app-icon>
    </div>

    <div class="container content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ion-content>
