/**
 * @param phone номер формата +7 (123) 456-78-90
 * @returns номер формата 1234567890
 */
export function getFormattedPhone(phone: string): string {
  return phone.replace(/\D/g, '').slice(-10);
}

/**
 * @param phone номер формата 1234567890
 * @returns номер формата +7 (123) 456-78-90
 */
export function convertInputPhone(phone: string): string {
  let tempPhone = phone;

  if (tempPhone.length === 10) {
    tempPhone = '7' + tempPhone;
  }

  return '+7 (' + tempPhone.slice(1, 4) + ') ' + tempPhone.slice(4, 7) + '-' + tempPhone.slice(7, 9) + '-' + tempPhone.slice(9, 11);
}
