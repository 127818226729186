<ng-container
  *ngIf="{
  isSendingCode: isSendingCode$ | async,
  authErrorMessage: authErrorMessage$ | async,
  inputPhone: inputPhone$ | async,
  isTimeout: isTimeout$ | async,
  secondsLeft: secondsLeft$ | async
} as data"
>
  <div class="title text-20 text-semibold">
    <app-icon class="icon" icon="toolbar-left" (click)="goToInputPhone()"></app-icon>
    <p>Введите код из СМС</p>
  </div>

  <p class="hint text-center">Код подтверждения отправлен<br />на номер телефона {{ data.inputPhone }}</p>

  <form
    [formGroup]="form"
    class="form form-code"
    [class.locked]="data.isSendingCode"
    [class.error]="!!data.authErrorMessage"
    (submit)="checkVerificationCode()"
  >
    <div class="numbers">
      <input class="n1 text-18 text-semibold text-center" formControlName="n1" mask="0" type="number" />
      <input class="n2 text-18 text-semibold text-center" (keydown)="onKeyDown($event, 'n1')" formControlName="n2" mask="0" type="number" />
      <input class="n3 text-18 text-semibold text-center" (keydown)="onKeyDown($event, 'n2')" formControlName="n3" mask="0" type="number" />
      <input class="n4 text-18 text-semibold text-center" (keydown)="onKeyDown($event, 'n3')" formControlName="n4" mask="0" type="number" />
    </div>

    <p *ngIf="data.authErrorMessage as error" class="error-msg mt-1 text-14 text-center">{{ error }}</p>
  </form>

  <button [disabled]="data.isTimeout" class="btn-base resend-code-btn" (click)="resendVerificationCode(data.inputPhone)">
    <div class="button-inner">
      <div class="button-inner__left">
        <app-icon class="icon" icon="clockwise"></app-icon>
        <span>Отправить новый код</span>
      </div>
      <span *ngIf="+data.secondsLeft">{{ data.secondsLeft }} сек</span>
    </div>
  </button>
</ng-container>
