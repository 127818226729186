<div
  class="input input-theme-{{theme}}"
  [ngClass]="{
    'input--error' : ngControl.invalid && ngControl.touched,
    'input--done' : isDone,
    'input--has-value' : hasValue,
    'input--custom-message' : customMessage
  }"
  #container
>
  <div class="input__wrapper">
    <label [ngClass]="{ 'floating' : placeholderFloat }" class="input__placeholder">{{ placeholder }}</label>
    <input
      class="input__area"
      [attr.data-cp]="cloudpaymentsField"
      (input)="onInput()"
      (blur)="onBlur()"
      [ngClass]="[customInputClass ? customInputClass : '']"
      [type]="inputType"
      [formControl]="$any(ngControl.control)"
      [required]="isRequired"
      [prefix]="prefix || ''"
      [attr.maxlength]="inputMaxLength"
      [mask]="mask"
      [id]="inputId"
      [autocomplete]="autocomplete"
      [showMaskTyped]="showMaskTyped ? showMaskTyped : null"
      [tabindex]="inputTabIndex || null"
      [attr.inputmode]="inputmode"
      [pattern]="pattern"
      #input
    />
    <ion-spinner *ngIf="isLoading" class="icon-loading" name="bubbles"></ion-spinner>

    <app-icon *ngIf="showArrow" icon="arrow-right" class="arrow"></app-icon>
  </div>

  <div class="error" *ngIf="errorMessage && ngControl.invalid" [innerHTML]="errorMessage"></div>

  <div class="custom-message" *ngIf="customMessage && ngControl.valid" [innerHTML]="customMessage"></div>
</div>
