import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, from, map, Observable, switchMap, tap } from 'rxjs';

import { PaymentDetails, Card, PaymentSbpInfoResponse, PaymentByCard, PayCryptogramResponse, CloudPaymentsNewCard } from '@shared/models';
import { CardPaymentMethodsEnum, PaymentRespStatusesEnum, PaymentSbpStatusEnum } from '@shared/enums';
import { RedirectByFormService, WindowRefService } from '../common';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private http: HttpClient,
    private redirectByFormService: RedirectByFormService,
    private windowRefService: WindowRefService,
  ) {}

  public payByNewCard(
    paymentId: string,
    publicId: string,
    fieldValues: CloudPaymentsNewCard,
    saveCard: boolean,
    paymentMethod = CardPaymentMethodsEnum.card,
  ): Observable<any> {
    const paymentUrl = environment.url.payCryptogram;

    const checkout = new this.windowRefService.nativeWindow.cp.Checkout({ publicId });

    return from(checkout.createPaymentCryptogram(fieldValues)).pipe(
      tap(console.warn),
      switchMap((cryptogram: string) => {
        const paymentModel: PaymentByCard = {
          paymentId,
          cardHolderName: fieldValues.name,
          name: fieldValues.name,
          cryptogram,
          saveCard,
          paymentMethod,
        };

        return this.http.post<PayCryptogramResponse>(paymentUrl, paymentModel);
      }),
      tap(console.warn),
      map((resp: PayCryptogramResponse): PayCryptogramResponse | Observable<never> => {
        if (resp.status === PaymentRespStatusesEnum.need3ds) {
          const formInputs = {
            PaReq: resp.model.PaReq,
            MD: resp.model.MD,
            TermUrl: resp.model.TermUrl,
          };

          this.redirectByFormService.post(formInputs, resp.model.AscUrl, '_self');
        }

        return resp;
      }),
      catchError(errors => {
        console.log(errors);

        return EMPTY;
      }),
    );
  }

  payBySavedCard(paymentId: string, cardId: string): Observable<any> {
    const url = environment.url.payToken;
    const model = {
      cardGuid: cardId,
      paymentId,
    };

    return this.http.post<Card[]>(url, model);
  }

  getPaymentDetails(paymentId: string): Observable<PaymentDetails> {
    const url = `${environment.url.paymentInfo}/${paymentId}`;

    return this.http.get<PaymentDetails>(url);
  }

  getCardsByPaymentId(paymentId: string): Observable<Card[]> {
    const url = `${environment.url.cards}?paymentGuid=${paymentId}`;

    return this.http.get<Card[]>(url);
  }

  payBySbp(paymentId: string, paymentMode: 'Image' | 'Link' = 'Link'): Observable<PaymentSbpInfoResponse> {
    return this.http.post<PaymentSbpInfoResponse>(environment.url.paymentSbp, { paymentId, paymentMode });
  }

  getPaymentSbpStatus(paymentId: string): Observable<PaymentSbpStatusEnum> {
    const url = `${environment.url.paymentSbp}/status/${paymentId}`;
    return this.http.get<PaymentSbpStatusEnum>(url);
  }
}
