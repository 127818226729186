<app-modal-layout [closeIcon]="true" (onGoBack)="closeModal()" (onClose)="closeModal()">
  <div class="dish-details  modification-{{ dish?.containerType | dishTransform  }}" *ngIf="!isFromMenuExample">
    <div class="img-block">
      <picture class="img-container" *ngIf="!isArray(dish.id)" [ngStyle]="dish?.id | customSize">
        <source
          [fallbackSrc]="'/assets/images/client-menu/empty-dish.png'"
          [appValidateSrcset]="getImageSources(dish?.id, ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp) + ', ' + getImageSourcesRetina(dish?.id, ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp)"
          type="image/webp"
        />
        <img
          class="img"
          [alt]="dish.title"
          [src]="getImageSources(dish?.id, ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
          [srcset]="getImageSourcesRetina(dish?.id, ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
        />
      </picture>
      <picture class="img-container" *ngIf="isArray(dish.id)">
        <source
          [fallbackSrc]="'/assets/images/client-menu/empty-dish.png'"
          [appValidateSrcset]="getImageSources(dish?.id[0], ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp) + ', ' + getImageSourcesRetina(dish?.id[0], ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp)"
          type="image/webp"
        />

        <img
          class="img"
          [alt]="dish.title"
          [src]="getImageSources(dish?.id[0], ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
          [srcset]="getImageSourcesRetina(dish?.id[0], ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
        />
      </picture>
    </div>
    <div class="dish-details__content">
      <div class="dish-details__header">
        <div class="menu-type" *ngIf="dish?.mealType">{{ ruDishTypeEnum[dish?.mealType] }}</div>
        <div class="calories" *ngIf="!isArray(dish?.grams)">{{ dish?.grams }} г / {{ dish?.caloricity }} <span>ккал</span></div>
      </div>
      <div class="dish-title">{{dish.title}}</div>
      <div class="dish-tooltip">
        <div class="dish-tooltip-header" *ngIf="!isArray(dish.id)">
          <div class="dish-tooltip-item">
            <span>Каллории</span>
            <b>{{dish.caloricity}} ккал</b>
          </div>
          <div class="dish-tooltip-item">
            <span>Белки</span>
            <b>{{dish.proteins}}</b>
          </div>
          <div class="dish-tooltip-item">
            <span>Жиры</span>
            <b>{{dish.fats}}</b>
          </div>
          <div class="dish-tooltip-item">
            <span>Углеводы</span>
            <b>{{dish.carbs}}</b>
          </div>
        </div>
        <div class="multi-dish-wrapper" *ngIf="isArray(dish.id)">
          <div class="dish-tooltip-header multi-dish" *ngFor="let id of dish?.id; let i = index">
            <div class="dish-tooltip-item">
              <span>Грамм</span>
              <b>{{dish?.grams[i][id]}} ккал</b>
            </div>
            <div class="dish-tooltip-item">
              <span>Каллории</span>
              <b>{{dish?.caloricity[i][id]}} ккал</b>
            </div>
            <div class="dish-tooltip-item">
              <span>Белки</span>
              <b>{{dish?.proteins[i][id]}}</b>
            </div>
            <div class="dish-tooltip-item">
              <span>Жиры</span>
              <b>{{dish?.fats[i][id]}}</b>
            </div>
            <div class="dish-tooltip-item">
              <span>Углеводы</span>
              <b>{{dish?.carbs[i][id]}}</b>
            </div>
          </div>
        </div>
        <div class="dish-tooltip-content">
          <div class="content-title">Ингредиенты</div>
          {{content}}
        </div>
      </div>
      <app-select-dish-modal
        *ngIf="!isFromMenuExample"
        [dish]="dish"
        [package]="package"
        [isFromAdditionModal]="isFromAdditionModal"
        [isFromDetailsModal]="true"
        [isFromSelectDishModal]="isFromSelectDishModal"
        [subscriptionStatus]="subscriptionStatus"
        (undoSelect)="deselect(dish)"
        (rateDish)="setDishRating(dish, package)"
      ></app-select-dish-modal>
    </div>
  </div>
  <div class="dish-details  modification-{{ menuExampleDish?.containerType | dishTransform  }}" *ngIf="isFromMenuExample">
    <div class="img-block">
      <picture class="img-container">
        <source
          [fallbackSrc]="'/assets/images/client-menu/empty-dish.png'"
          [appValidateSrcset]="getImageSources(menuExampleDish?.code, ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp) + ', ' + getImageSourcesRetina(menuExampleDish?.code, ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp)"
          type="image/webp"
        />
        <img
          class="img"
          [alt]="menuExampleDish.name"
          [src]="getImageSources(menuExampleDish?.code, ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
          [srcset]="getImageSourcesRetina(menuExampleDish?.code, ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
        />
      </picture>
    </div>
    <div class="dish-details__content">
      <div class="dish-details__header">
        <div class="menu-type" *ngIf="menuExampleDish?.type">{{ ruDishTypeEnum[menuExampleDish?.type] }}</div>
        <div class="calories">{{ menuExampleDish?.weight }} г / {{ menuExampleDish?.calories }} <span>ккал</span></div>
      </div>
      <div class="dish-title">{{menuExampleDish.name}}</div>
      <div class="dish-tooltip">
        <div class="dish-tooltip-header">
          <div class="dish-tooltip-item">
            <span>Каллории</span>
            <b>{{menuExampleDish.calories}} ккал</b>
          </div>
          <div class="dish-tooltip-item">
            <span>Белки</span>
            <b>{{menuExampleDish.proteins}}</b>
          </div>
          <div class="dish-tooltip-item">
            <span>Жиры</span>
            <b>{{menuExampleDish.fat}}</b>
          </div>
          <div class="dish-tooltip-item">
            <span>Углеводы</span>
            <b>{{menuExampleDish.carbohydrates}}</b>
          </div>
        </div>
        <div class="dish-tooltip-content">
          <div class="content-title">Ингредиенты</div>
          {{!isFromMenuExample ? content : menuExampleContent}}
        </div>
      </div>
      <app-select-dish-modal
        *ngIf="!isFromMenuExample"
        [dish]="dish"
        [package]="package"
        [isFromAdditionModal]="isFromAdditionModal"
        [isFromDetailsModal]="true"
        [isFromSelectDishModal]="isFromSelectDishModal"
        [subscriptionStatus]="subscriptionStatus"
        (undoSelect)="deselect(dish)"
        (rateDish)="setDishRating(dish, package)"
      ></app-select-dish-modal>
    </div>
  </div>
</app-modal-layout>
