import { CurrentProgram } from '@shared/models';
import { ProgramModesEnum, ProgramTypesEnum } from '@shared/enums';
import { capitalize, formatToDuration, getExtendedOptions } from '.';

/**
 * @param params - параметры из адресной строки: `menuType`, `caloriesMode`, `daysCount`, `autoRenewal`, `isSplittable`, `mode`
 * @returns параметры программы
 */
export function convertParamsFromUrlIntoProgram(params: URLSearchParams): Partial<CurrentProgram> {
  const programParams: Partial<CurrentProgram> = {};

  if (!params.size) {
    return programParams;
  }

  if (params.has('menuType')) {
    programParams.id = capitalize(params.get('menuType').toLowerCase()) as ProgramTypesEnum;
  }
  if (params.has('caloriesMode')) {
    programParams.dailyCalories = parseInt(params.get('caloriesMode'));
  }
  if (params.has('daysCount')) {
    programParams.durationId = formatToDuration(parseInt(params.get('daysCount')));
    programParams.durationInDays = parseInt(params.get('daysCount'));
  }

  if (params.has('isSplittable')) {
    programParams.isSplittable = params.get('isSplittable').toLowerCase() === 'true';
  }

  const options: {
    daysCount: number;
    mode?: ProgramModesEnum;
    autoRenew: boolean;
  } = {
    daysCount: parseInt(params.get('daysCount')),
    autoRenew: params.get('autoRenewal')?.toLowerCase() === 'true',
    mode: params.get('mode').toLowerCase() === 'nobreakfest' ? ProgramModesEnum.NoBreakfast : ProgramModesEnum.Full,
  };

  programParams.extOptions = getExtendedOptions(options);

  return programParams;
}
