import { ChangeDetectionStrategy, Input, Component, inject } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { RudderStackService } from '@shared/services';
import { ClientSubscriptionDetails, ClientSubscriptionPackage } from '@shared/models';
import { SubscriptionStatusEnum } from '@shared/enums';
import { ClientMenuSelectors, StartCustomization } from '@store/client-menu';
import { ClientMenuEventsEnum, PackageStateEnum } from '@modules/client-menu/enums';
import { ClientMenuDish, CustomizationBasket, CustomizationItem } from '@modules/client-menu/models';
import { DishAdditionDialogComponent } from '../dish-addition-dialog';

@Component({
  selector: 'app-dishes-list',
  templateUrl: './dishes-list.component.html',
  styleUrls: ['./dishes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishesListComponent {
  @Input() scrollableContainer: HTMLElement;

  public activeSubscriptionDetails$: Observable<ClientSubscriptionDetails> = inject(Store).select(ClientMenuSelectors.subscriptionDetails);
  public activePackage$: Observable<ClientSubscriptionPackage> = inject(Store).select(ClientMenuSelectors.activePackage);
  public convertedDishesList$: Observable<ClientMenuDish[]> = this.activePackage$.pipe(
    filter(Boolean),
    map(({ customizationBasket, menuItems }) => this.convertDishesList(customizationBasket, menuItems)),
  );

  public additionDisablementStatus$: Observable<boolean> = this.activePackage$.pipe(
    filter<ClientSubscriptionPackage>(Boolean),
    map(({ hasCustomization, isCustomizable, availableAdditionsCount, packageState, hasConfirmedCustomization, customizationBasket }) => {
      // Если поле originalDish пустое, значит блюдо было не заменено, а добавлено! Считаем такие блюда!
      const addedDishesCount = customizationBasket?.items.filter(({ originalDish }) => !originalDish).length || 0;
      const isAdditionsLimitReached = availableAdditionsCount - addedDishesCount <= 0;
      const status =
        packageState !== PackageStateEnum.onCooking &&
        isCustomizable &&
        !hasCustomization &&
        !hasConfirmedCustomization &&
        !isAdditionsLimitReached;

      return status;
    }),
  );

  public readonly SubscriptionStatusEnum = SubscriptionStatusEnum;

  constructor(private modalCtrl: ModalController, private store: Store, private rudderstack: RudderStackService) {}

  /**
   * Добавляем/обновляем список блюд на день с учетом кастомизаций/замен/добавлений и возможного повышения цены
   * @param packageItem текущий пакет на день
   */
  private convertDishesList(customizationBasket: CustomizationBasket, dishes: ClientMenuDish[]): ClientMenuDish[] {
    // Если поле customizationBasket пустое, значит кастомизаций в пакете нет и просто возвращаем текущий список блюд
    if (!customizationBasket) {
      return dishes;
    }

    const convertedDishes: ClientMenuDish[] = [];

    //
    // ШАГ 1: добавляем замены/кастомизации текущих блюд
    //
    dishes.forEach(dish => {
      // Проверяем, есть ли кастомизация у блюда из списка
      const item: CustomizationItem = customizationBasket.items.find(({ originalDish }) => originalDish?.id === dish.id);

      // Если кастомизации нет, то оставляем блюдо в списке без изменений
      if (!item) {
        convertedDishes.push(dish);
      } else {
        // Если есть, то добавляем новое (замененное) блюдо и помечаем его соотв. флагами
        const convertedDish: ClientMenuDish = {
          ...item.newDish,
          shouldBeConfirmed: true,
          customizationId: item.id,
          priceIncrease: item.priceIncrease,
        };

        convertedDishes.push(convertedDish);
      }
    });

    //
    // ШАГ 2: добавляем в список блюда, которые были добавлены клиентом дополнительно
    //
    customizationBasket.items.forEach(item => {
      // Если поле originalDish пустое, значит блюдо было не заменено, а добавлено!
      if (!item.originalDish) {
        const additionalDish: ClientMenuDish = {
          ...item.newDish,
          shouldBeConfirmed: true,
          customizationId: item.id,
          priceIncrease: item.priceIncrease,
        };

        convertedDishes.push(additionalDish);
      }
    });

    return convertedDishes;
  }

  public async openDishAdditionDialog(
    selectedPackage: ClientSubscriptionPackage,
    isChildrenView: boolean,
    subscriptionStatus: SubscriptionStatusEnum,
  ): Promise<void> {
    if (subscriptionStatus !== SubscriptionStatusEnum.Active) {
      return;
    }

    this.rudderstack.track(ClientMenuEventsEnum.addDishBtnClick);

    const modal = await this.modalCtrl.create({
      component: DishAdditionDialogComponent,
      cssClass: 'dish-addition-modal',
      mode: 'md',
      componentProps: {
        package: selectedPackage,
        isChildrenView,
      },
    });

    modal.present();

    const result = await modal.onDidDismiss();
    const addedDish: ClientMenuDish = result.data;

    if (addedDish) {
      this.store.dispatch(new StartCustomization(selectedPackage.packageId, null, addedDish.id));

      this.rudderstack.track(ClientMenuEventsEnum.additionalDishSelected, {
        dishId: addedDish.id,
        title: addedDish.title,
      });
    }

    this.rudderstack.track(ClientMenuEventsEnum.dishAdditionPopupClosed);
  }
}
